/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .counter-row {
    margin-bottom: 88px;
  }
  .counter span {
    font-size: 2.2rem !important;
  }
  .counter-subhead {
    font-size: 1.1rem !important;
  }
  .counter-col {
    padding-top: 15px !important;
  }
  .core{
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .counter-row {
    margin-bottom: 88px;
  }
  .counter span {
    font-size: 2.2rem !important;
  }
  .counter-subhead {
    font-size: 1.1rem !important;
  }
  .counter-col {
    padding-top: 15px !important;
  }
  .core{
    margin-bottom: 0px !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .counter-row {
    margin-top: 25px !important;
    margin-bottom: 88px;
  }
  .counter span {
    font-size: 3rem !important;
  }
  .counter-subhead {
    font-size: 1.28rem !important;
  }
  .counter-col {
    padding-top: 25px !important;
  }
}

.counter-row {
  margin-bottom: 88px;
}

.counter span {
  color: #f9ac08;
  font-size: 3.42rem;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
}

.counter-subhead {
  color: #131313;
  text-align: center;
  font-size: 1.57rem;
  font-family: "Poppins";
  font-weight: 700;
}
