/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .navbar-nav {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .nav-link {
    margin-left: 0px !important;
    padding-top: 3px !important;
  }
  .navbar-logo {
    width: 85% !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .navbar-nav {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .nav-link {
    margin-left: 0px !important;
    padding-top: 3px !important;
  }
  .navbar-logo {
    width: 85% !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .navbar-nav {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 25px !important;
  }
  .nav-link {
    margin-left: 0px !important;
  }
}

/* Sticky */
.navbar.nav-sticky {
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  position: fixed !important;
  width: 100%;
  top: 0 !important;
  margin: 0;
  z-index: 100;
  -webkit-animation: mouse-scroll 0.2s ease-in-out;
}

@keyframes mouse-scroll {
  100% {
    opacity: 0.5;
  }
}

.navbar {
  background-color: #fafafa;
}

.nav-link {
  color: #000;
  font-family: "Poppins";
  margin-left: 30px;
}

.navbar-logo {
  user-select: none;
  -webkit-user-drag: none;
}

.nav-link.active::after {
  content: url("../../assets/navbar/line.png");
  position: relative;
  left: -50%;
  bottom: 0;
  top: -20px;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-link.active {
  color: black !important;
  font-weight: 600;
  margin-right: -22px;
}
