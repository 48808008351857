/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .footer-col {
    display: grid !important;
    justify-content: center !important;
  }
  .address {
    text-align: center !important;
  }
  .address,
  .mail a,
  .phone a,
  .links-col a,
  .footer-media-head {
    display: flex !important;
    justify-content: center !important;
  }
  .links-col {
    padding-top: 25px !important;
  }
  .footer-media {
    display: grid !important;
    justify-content: center !important;
    padding-top: 25px !important;
  }
  .phone {
    display: flex !important;
    justify-content: space-evenly !important;
  }
  .footer-logo {
    display: flex !important;
    justify-content: center !important;
  }
  .f-media {
    width: 31px !important;
    height: 31px !important;
  }
  .insta-img {
    height: 20px !important;
  }
  .facebook-img {
    height: 18px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .footer-col {
    display: grid !important;
    justify-content: center !important;
  }
  .address {
    text-align: center !important;
  }
  .address,
  .mail a,
  .phone a,
  .links-col a,
  .footer-media-head {
    display: flex !important;
    justify-content: center !important;
  }
  .links-col {
    padding-top: 25px !important;
  }
  .footer-media {
    display: grid !important;
    justify-content: center !important;
    padding-top: 25px !important;
  }
  .footer-logo {
    display: flex !important;
    justify-content: center !important;
  }
  .f-media {
    width: 31px !important;
    height: 31px !important;
  }
  .insta-img {
    height: 20px !important;
  }
  .facebook-img {
    height: 18px !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .footer-col {
    display: grid !important;
    justify-content: center !important;
  }
  .address {
    text-align: center !important;
  }
  .address,
  .mail a,
  .phone a,
  .links-col a,
  .footer-media-head {
    display: flex !important;
    justify-content: center !important;
  }
  .links-col {
    padding-top: 25px !important;
  }
  .footer-media {
    display: grid !important;
    justify-content: center !important;
    padding-top: 25px !important;
  }
  .footer-logo {
    display: flex !important;
    justify-content: center !important;
  }
  .f-media {
    width: 31px !important;
    height: 31px !important;
  }
  .insta-img {
    height: 20px !important;
  }
  .facebook-img {
    height: 18px !important;
  }
}

.footer-ct {
  background: #011c29;
}

.footer-row {
  padding-top: 120px;
  padding-bottom: 120px;
}

.address {
  color: #cccccc;
  font-family: "Poppins";
  padding-top: 20px;
}

.mail,
.phone {
  padding-top: 20px;
}

.address a,
.mail a,
.phone a {
  color: #cccccc;
  font-family: "Poppins";
  text-decoration: none;
}

.address a:hover {
  color: #0098e5;
}

.phone a:hover {
  color: #0098e5;
}

.mail a:hover {
  color: #0098e5;
}

.links {
  color: #fafafa;
  font-size: 1.5rem;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 124.5%;
}

.links-col {
  display: grid;
  justify-content: center;
}

.links-col a {
  text-decoration: none;
  color: #cccccc;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 124.5%;
}

.links-col a:hover {
  color: #0098e5;
}

.footer-media-head {
  color: #fafafa;
  font-size: 1.5rem;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 124.5%;
}

.footer-media {
  display: flex;
  justify-content: center;
}

.f-media {
  background-color: #cccccc;
  width: 28px;
  height: 28px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-media a {
  display: flex;
  align-items: center;
  color: #bababa;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 124.5%;
  text-decoration: none;
}

.footer-media a:hover {
  color: #0098e5;
}

.copyright {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
}

.copyright a {
  text-decoration: none;
  color: #000;
}

.copyright a:hover{
  text-decoration: underline;
}

.utube {
  display: none;
}

.footer-brand {
  user-select: none;
  -webkit-user-drag: none;
}

.facebook-img,
.insta-img,
.utube-img {
  user-select: none;
  -webkit-user-drag: none;
}
