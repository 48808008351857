/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .down-arrow {
    top: 22% !important;
    padding-left: 25% !important;
  }
  .enq-head,.enq-data {
    text-align: center !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .down-arrow {
    top: 22% !important;
    padding-left: 25% !important;
  }
  .enq-head,.enq-data {
    text-align: center !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .down-arrow {
    padding-left: 54% !important;
  }
}

/* fullhd */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  .enqr-btn {
    right: 25% !important;
  }
  .enq-row {
    margin-top: 100px !important;
    padding-bottom: 130px !important;
  }
  .down-arrow {
    padding-bottom: 120px !important;
  }
  .enqr-btn {
    margin-top: 70px !important;
  }
  .enq-data {
    font-size: 1.14rem !important;
  }
}

#enquire {
  background-image: url("../../assets/enquire/enquire-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
}

.enquire-head {
  color: white;
  padding-top: 35px;
  margin-bottom: 0px;
}

.ui {
  position: relative;
  display: flex;
  justify-content: center;
}

.enq-head {
  color: #fafafa;
  font-size: 1.85rem;
  font-family: "Montserrat";
  font-weight: 700;
  line-height: 29px;
}

.enq-row {
  margin-top: 50px;
  padding-bottom: 60px;
}

.enq-data {
  color: #fafafa;
  font-size: 1rem;
  font-family: "Montserrat";
  line-height: 29px;
  font-weight: 400;
  padding-top: 15px;
}

.enqr-btn {
  background-color: white !important;
  border-radius: 30px !important;
  border: white;
  position: absolute;
  right: 15%;
  margin-top: 40px;
}

.down-arrow {
  position: relative;
  top: 30%;
  padding-left: 22%;
  padding-bottom: 50px;
}
