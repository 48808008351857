/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  #study-destination {
    margin-top: 50px !important;
  }
  .round-ring {
    width: 75% !important;
  }
  .study {
    top: 20px !important;
    left: 50% !important;
    text-align: center !important;
    padding-top: 0px !important;
    transform: translateX(-50%) !important;
    width: 100% !important;
  }
  .study-btn {
    top: 97% !important;
  }
  .study-head {
    font-size: 20px !important;
  }
  .study-content {
    text-align: center !important;
  }
  .study-data {
    height: 530px !important;
  }
  .destination-row {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  #study-destination {
    margin-top: 50px !important;
  }
  .study {
    top: 20px !important;
    left: 50% !important;
    text-align: center !important;
    padding-top: 0px !important;
    transform: translateX(-50%) !important;
    width: 100% !important;
  }
  .round-ring {
    width: 67% !important;
  }
  .study-btn {
    top: 97% !important;
  }
  .study-head {
    font-size: 20px !important;
  }
  .study-content {
    text-align: center !important;
  }
  .study-data {
    height: 460px !important;
  }
  .destination-row {
    margin-top: 0px !important;
  }
}
@media screen and (min-width: 380px) and (max-width: 395px) {
  .study-data {
    height: 500px !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .round-ring {
    width: 55% !important;
  }
  .study {
    top: 25px !important;
    left: 50% !important;
    text-align: center !important;
    padding-top: 0px !important;
    transform: translateX(-50%) !important;
    width: 100% !important;
  }
  .study-btn {
    top: 94% !important;
  }
  .study-content {
    min-height: 205px !important;
  }
  .study-data {
    height: 300px !important;
  }
  .destination-row {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 1360px) and (max-width: 13900px) {
  .study {
    top: 35px !important;
    left: 50% !important;
  }
}

/* mac */

@media screen and (min-width: 1400px) and (max-width: 1700px) {
  .study {
    top: 35px !important;
    left: 50% !important;
  }
  .study-btn {
    top: 96% !important;
  }
  .study-data {
    height: 440px !important;
  }
}

/* fullhd */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  .study {
    top: 35px !important;
    left: 50% !important;
  }
  .round-ring {
    width: 25% !important;
  }
  .study-btn {
    top: 96% !important;
  }
  .study-data {
    height: 440px !important;
  }
}

#study-destination {
  position: relative;
  margin-top: 88px;
  padding-bottom: 88px;
}

.round-ring {
  position: relative;
  margin: auto;
  display: flex;
  width: 35%;
}

.study {
  position: absolute;
  top: 30px;
  left: 50%;
  text-align: center;
  padding-top: 0px;
  transform: translateX(-50%);
  width: 100%;
}

.destination-row {
  margin-top: 50px;
}

.destination-col {
  margin-top: 60px;
}

.human {
  display: flex;
  margin: auto;
  -webkit-user-drag: none;
  user-select: none;
}

.study-data {
  border-radius: 18px;
  background-color: #09232f;
  position: relative;
  height: 500px;
}

.study-head {
  color: #fafafa;
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 33px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 15px;
  margin: 0px 15px;
}

.study-content {
  color: #cccccc;
  line-height: 28px;
  letter-spacing: 0.28px;
  padding: 5px 28px;
  padding-bottom: 60px;
  position: relative;
  font-size: 1rem;
  text-align: justify;
}

.study-btn {
  top: 96%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
}
