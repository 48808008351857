/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .cr1,
  .cr4 {
    width: 350px !important;
    height: 570px !important;
    margin: auto !important;
  }

  .cr2 {
    width: 350px !important;
    height: 570px !important;
    margin: auto !important;
  }
  .cr3 {
    width: 350px !important;
    height: 570px !important;
    margin: auto !important;
  }
  .cr-data {
    width: 260px !important;
    padding-top: 35px !important;
  }
  .dp {
    padding-top: 20px !important;
  }
  .dp1 {
    padding-top: 45px !important;
  }

  .dp,
  .cr-data,
  .designation {
    padding-left: 10px !important;
  }
  .name {
    font-size: 26px !important;
    right: -43% !important;
  }
  .name-col {
    position: relative !important;
    top: -50% !important;
  }
  .react-multiple-carousel__arrow--left {
    right: 14% !important;
  }

  .react-multiple-carousel__arrow--right {
    left: 14% !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .cr1,
  .cr4 {
    width: 375px !important;
    height: 570px !important;
    margin: auto !important;
  }

  .cr2 {
    width: 350px !important;
    height: 570px !important;
    margin: auto !important;
  }
  .cr3 {
    width: 350px !important;
    height: 570px !important;
    margin: auto !important;
  }

  .cr-data {
    width: 260px !important;
    padding-top: 35px !important;
  }
  .dp {
    padding-top: 20px !important;
  }

  .dp1 {
    padding-top: 45px !important;
  }

  .dp,
  .cr-data,
  .designation {
    padding-left: 10px !important;
  }
  .name {
    font-size: 26px !important;
    right: -43% !important;
  }
  .name-col {
    position: relative !important;
    top: -50% !important;
  }
  .react-multiple-carousel__arrow--left {
    right: 14% !important;
  }

  .react-multiple-carousel__arrow--right {
    left: 14% !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .cr1,
  .cr2,
  .cr3,
  .cr4 {
    width: 384px !important;
    height: 590px !important;
    margin: auto !important;
  }

  .cr-data {
    width: 260px !important;
    padding-top: 35px !important;
  }
  .dp {
    padding-top: 15px !important;
  }

  .dp1 {
    padding-top: 45px !important;
  }

  .dp,
  .cr-data,
  .designation {
    padding-left: 10px !important;
  }
  .name {
    font-size: 26px !important;
    right: -43% !important;
  }
  .name-col {
    position: relative !important;
    top: -58% !important;
  }
  .react-multiple-carousel__arrow--left {
    right: 10% !important;
  }

  .react-multiple-carousel__arrow--right {
    left: 10% !important;
  }
}

/* mac  */

@media screen and (min-width: 1400px) and (max-width: 1700px) {
  .cr1,
  .cr2,
  .cr3,
  .cr4 {
    width: auto !important;
  }
}

/* hp  */

@media screen and (min-width: 1350px) and (max-width: 1390px) {
  .cr1,
  .cr2,
  .cr3,
  .cr4 {
    width: auto !important;
  }
}

/* lenovo  */

@media screen and (min-width: 1970px) and (max-width: 1990px) {
  .cr1,
  .cr2,
  .cr3,
  .cr4 {
    width: auto !important;
  }
}

/* fullhd */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  .cr1,
  .cr2,
  .cr3,
  .cr4 {
    width: auto !important;
  }
}

#testimonials {
  margin-bottom: 130px;
}

.cr-data {
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  width: 500px;
  padding-top: 40px;
  padding-left: 20px;
  text-align: left;
  min-height: 250px;
}

.cr1 {
  background: #b2a4ff;
  width: 639px;
  height: 410px;
  display: flex;
}

.cr2 {
  background: #dfa800;
  width: 639px;
  height: 410px;
  display: flex;
}

.cr3 {
  background: #ff8dc7;
  width: 639px;
  height: 410px;
  display: flex;
}

.cr4 {
  background: #4d09d3;
  width: 639px;
  height: 410px;
  display: flex;
}

.dp {
  padding-left: 20px;
  padding-top: 50px;
}

.name {
  transform: rotate(-90deg);
  float: right;
  position: relative;
  right: -35%;
  color: #fafafa;
  font-size: 26px;
  font-family: "Montserrat";
  font-weight: 800;
  white-space: nowrap;
}

.name-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.designation {
  color: #fafafa;
  font-size: 16px;
  font-family: "Poppins";
  padding-left: 20px;
  padding-top: 10px;
}

.dp-img {
  object-fit: contain;
  user-select: none;
  -webkit-user-drag: none;
}

.female {
  padding-bottom: 7px;
}

.fr {
  background-color: black;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  z-index: 99 !important;
}

.react-multiple-carousel__arrow--left::before {
  content: url("../../assets/testimonials/prev.svg");
}

.react-multiple-carousel__arrow--right::before {
  content: url("../../assets/testimonials/next.svg");
}

.react-multi-carousel-list {
  overflow: unset;
  overflow-x: clip;
}

.react-multiple-carousel__arrow {
  top: 105%;
}

.react-multiple-carousel__arrow--left {
  right: 7%;
}

.react-multiple-carousel__arrow--right {
  left: 7%;
}

#testimonials li {
  list-style-image: none;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  width: 0%;
  margin: auto;
}

#testimonials li::before {
  content: none;
}
