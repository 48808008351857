@media screen and (max-width: 1020px) {
  #header {
    display: none !important;
  }
}

.header-bg {
  background: #383838;
}

.header-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-number a {
  color: white;
  line-height: 21px;
  text-decoration: none;
  font-size: 12px;
}

.contact-number a:hover {
  color: #0098e5;
}

.header-media {
  display: flex;
  justify-content: end;
  padding: 6px 0px;
  -webkit-user-drag: none;
  user-select: none;
  /* align-items: center; */
}

.no {
  display: none;
}

.in {
  user-select: none;
  -webkit-user-drag: none;
}
