/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  #why {
    background-size: cover !important;
  }
  .why-logo {
    width: 70% !important;
  }
  .fig {
    display: grid !important;
    justify-content: center !important;
  }
  .ic {
    margin-left: 0px !important;
  }
  .icon-bg {
    margin: auto !important;
  }
  .icons-col {
    padding-top: 25px !important;
    margin: auto !important;
  }
  .why-data {
    margin-top: 40px !important;
    text-align: center !important;
  }
  .head {
    padding-top: 0px !important;
    font-size: 50px !important;
    justify-content: center !important;
    align-items: end !important;
  }
  .why-row {
    padding-bottom: 90px !important;
  }
  .fi-lap {
    display: none !important;
  }
  .fi-mobile {
    display: flex !important;
    justify-content: space-evenly !important;
  }
  .rd {
    margin-left: 0px !important;
  }
  .head::after {
    margin-left: 10px !important;
    height: 65px !important;
  }
  .logo {
    display: flex !important;
    justify-content: center !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  #why {
    background-size: cover !important;
  }
  .why-logo {
    width: 70% !important;
  }
  .fig {
    display: grid !important;
    justify-content: center !important;
  }
  .ic {
    margin-left: 0px !important;
  }
  .icon-bg {
    margin: auto !important;
  }
  .icons-col {
    padding-top: 25px !important;
    margin: auto !important;
  }
  .why-data {
    margin-top: 40px !important;
    text-align: center !important;
  }
  .head {
    padding-top: 0px !important;
    font-size: 50px !important;
    justify-content: center !important;
  }
  .why-row {
    padding-bottom: 90px !important;
  }
  .fi-lap {
    display: none !important;
  }
  .fi-mobile {
    display: flex !important;
    justify-content: space-evenly !important;
  }
  .rd {
    margin-left: 0px !important;
  }
  .head::after {
    margin-left: 10px !important;
    height: 65px !important;
  }
  .logo {
    display: flex !important;
    justify-content: center !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  #why {
    background-size: cover !important;
  }
  .why-row {
    padding-bottom: 90px !important;
  }
  .head {
    padding-top: 0px !important;
    justify-content: center !important;
  }
  .why-logo {
    width: 60% !important;
  }
  .ic {
    margin-left: 0px !important;
  }
  .icon-bg {
    margin: auto !important;
  }
  .icons-col {
    padding-top: 25px !important;
  }
  .why-data {
    margin-top: 40px !important;
    text-align: center !important;
  }
  .fi-mobile {
    display: none !important;
  }
  .rd {
    margin-left: 0px !important;
  }
  .logo {
    display: flex !important;
    justify-content: center !important;
  }
}

/* mac */

@media screen and (min-width: 1300px) and (max-width: 1700px) {
  #why {
    background-size: cover !important;
  }
}

/* fullhd */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  #why {
    background-size: cover !important;
  }
}

@media screen and (min-width: 810px) {
  .fi-mobile {
    display: none !important;
  }
}

#why {
  background-image: url("../../assets/why/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.head {
  color: #fafafa;
  font-size: 3.38rem;
  line-height: 59px;
  padding-top: 90px;
  font-family: "poppins";
  display: flex;
  justify-content: left;
  align-items: end;
}

.head::after {
  content: url("../../assets/why/questionmark.svg");
  margin-left: 15px;
  height: 60px;
}

.why-row {
  padding-top: 80px;
  padding-bottom: 90px;
}

.why-data {
  color: #fafafa;
  text-transform: lowercase;
  text-align: justify;
}

.why-logo {
  user-select: none;
  -webkit-user-drag: none;
}

.icons {
  margin-top: 50px;
}

.fig {
  display: flex;
  justify-content: space-between;
}

.icon-bg {
  background-color: white;
  width: 90px;
  height: 90px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.icon-data {
  display: flex;
  justify-content: space-between;
}

.ic {
  color: #fafafa;
  font-size: 16px;
  padding-top: 10px;
  text-align: center;
  line-height: 25px;
}

.visa-icon,
.build-icon,
.turf-icon,
.map-icon {
  user-select: none;
  -webkit-user-drag: none;
}

.rd {
  margin-left: 90px;
}
