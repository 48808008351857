/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .banner-text {
    top: 20% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    width: 65% !important;
  }
  .eqry-btn {
    top: 74% !important;
  }
  .under-arrow {
    top: 60% !important;
    margin-left: 10px !important;
  }
  .under-arrow img {
    width: 27px !important;
  }
  #mouse-scroll {
    top: 85% !important;
  }
  .arr {
    top: 51% !important;
    left: 40% !important;
    width: 80px !important;
  }
  .down-arrow-1,
  .down-arrow-2,
  .down-arrow-3 {
    margin-top: -10px !important;
  }
  .down-arrow-1,
  .down-arrow-2 {
    height: 17px !important;
  }
  .downarrow {
    width: 10px !important;
  }
  .now {
    font-size: 8px !important;
    padding: 2px 10px !important;
    line-height: 2px !important;
  }
  .nr {
    width: 22px !important;
  }
  #homepage {
    position: relative !important;
  }
  #homepage .bt {
    padding: 3px 6px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .banner-text {
    top: 20% !important;
    font-size: 12px !important;
    line-height: 18px !important;
    width: 65% !important;
  }
  .eqry-btn {
    top: 66% !important;
  }
  .under-arrow {
    top: 50% !important;
    margin-left: 10px !important;
  }
  .under-arrow img {
    width: 35px !important;
  }
  #mouse-scroll {
    position: absolute;
    top: 85% !important;
  }
  .arr {
    top: 51% !important;
    left: 40% !important;
    width: 80px !important;
  }
  .downarrow {
    width: 10px !important;
  }
  .down-arrow-1,
  .down-arrow-2,
  .down-arrow-3 {
    margin-top: -10px !important;
  }
  .down-arrow-1,
  .down-arrow-2 {
    height: 17px !important;
  }
  #homepage .find {
    height: 24px !important;
  }
  .now {
    font-size: 8px !important;
    padding: 2px 10px !important;
    line-height: 2px !important;
  }

  #homepage .bt {
    padding: 3px 6px !important;
  }

  .nr {
    width: 22px !important;
  }
  #homepage {
    position: relative !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .banner-text {
    top: 20% !important;
    font-size: 1rem !important;
    line-height: 30px !important;
    width: 85% !important;
  }
  #homepage .eqry-btn {
    top: 47% !important;
  }
  .under-arrow {
    top: 36% !important;
  }
  #mouse-scroll {
    position: absolute;
    top: 33% !important;
  }
  .downarrow {
    width: 20px !important;
  }
  .down-arrow-1,
  .down-arrow-2,
  .down-arrow-3 {
    margin-top: -10px !important;
  }
  #homepage {
    position: relative !important;
  }
}

/* mac */

@media screen and (min-width: 1400px) and (max-width: 1700px) {
  .banner-text {
    top: 40% !important;
  }
  .under-arrow {
    top: 65% !important;
  }
  .eqry-btn {
    top: 73% !important;
  }
  .arr {
    top: 60% !important;
  }
  #mouse-scroll {
    top: 105% !important;
  }
}

/* fullhd */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  .banner-text {
    width: 48% !important;
  }
  .under-arrow {
    top: 78% !important;
    margin-left: 14% !important;
  }
  .eqry-btn {
    top: 85% !important;
  }
  .arr {
    top: 70% !important;
  }
  #mouse-scroll {
    top: 100% !important;
  }
}

.now {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-div {
  position: relative;
}

.banner-img {
  width: 100%;
  -webkit-user-drag: none;
  user-select: none;
}

.banner-text {
  font-size: 1.8rem;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 50px;
  position: absolute;
  top: 55%;
  color: white;
  width: 60%;
}

.arr {
  position: absolute;
  top: 85%;
  left: 40%;
}

.under-arrow {
  position: absolute;
  top: 88%;
  margin-left: 50px;
}

.eqry-btn {
  position: absolute;
  top: 97%;
}

.eqry-btn a {
  text-decoration: none;
}

.nxt-arrow {
  padding-left: 5px;
  padding-bottom: 3px;
}

#mouse-scroll .down-arrow-1,
#mouse-scroll .down-arrow-2,
#mouse-scroll .down-arrow-3 {
  -webkit-animation: mouse-scroll 1.5s infinite;
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#mouse-scroll {
  position: absolute;
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
}

#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
}

#mouse-scroll .down-arrow-3 {
  -webkit-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
}

.mice {
  display: grid;
}

.str a {
  scroll-behavior: smooth;
}
