/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .dat {
    display: grid !important;
  }
  .input-field {
    margin-left: 0% !important;
    width: 245px !important;
  }
  .contact_label {
    width: 100% !important;
    padding-bottom: 5px !important;
  }
  .md-head {
    padding-bottom: 40px !important;
  }
  .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
    justify-content: left !important;
    margin-left: 0% !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .dat {
    display: grid !important;
  }
  .input-field {
    margin-left: 0% !important;
    width: 280px !important;
  }
  .contact_label {
    width: 100% !important;
    padding-bottom: 5px !important;
  }
  .md-head {
    padding-bottom: 40px !important;
  }
  .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
    justify-content: left !important;
    margin-left: 0% !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .dat {
    display: grid !important;
  }
  .input-field {
    margin-left: 0% !important;
    width: 375px !important;
  }
  .contact_label {
    width: 100% !important;
    padding-bottom: 5px !important;
  }
  .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
    justify-content: left !important;
    margin-left: 0% !important;
  }
}

.enquire-modal .modal-content {
  background: linear-gradient(
      137deg,
      rgba(255, 250, 235, 0.1) 0%,
      rgba(255, 141, 199, 0.1) 100%
    ),
    #fad669;
  width: 88%;
  margin: auto;
}

.modal-header,
.modal-footer {
  border: none;
}

.md-head {
  font-size: 1.85rem;
  font-family: "Montserrat";
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  padding-bottom: 50px;
}

.modal-btn {
  margin: auto;
  margin-bottom: 40px;
}

.modal-button {
  background-color: white !important;
  border: none;
  color: #0b3d57 !important;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 30px;
  border-radius: 25px;
  padding: 4px 25px;
}

.dat {
  display: flex;
  align-items: center;
}

.input-field {
  margin-left: 19%;
  width: 365px;
  border: none;
  border-radius: 5px;
  height: 30px;
  outline: none;
  font-family: "Poppins";
  font-size: 12px;
}

.contact_label {
  width: 33%;
}

.consent {
  width: 100%;
  padding-left: 10px;
}

.box {
  display: flex;
}

.contact-apply {
  padding: 0px 10px;
}

.mandatory {
  color: red;
}

::placeholder {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 30px;
  padding: 5px;
  opacity: 0.8;
}

.modal-open {
  overflow: initial !important;
  padding-right: 0px !important;
}

/* alert  */

.css-1hdyuqk-MuiPaper-root-MuiAlert-root {
  display: flex;
  justify-content: center;
  background-color: transparent !important;
  margin-left: 20%;
}
