/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  .sub-heading {
    font-size: 22px !important;
    padding-top: 50px !important;
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  .sub-heading {
    font-size: 22px !important;
    padding-top: 50px !important;
    margin-bottom: 25px !important;
  }
}

body {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  scroll-behavior: smooth;
}

.sub-heading {
  color: #131313;
  font-size: 36px;
  font-family: "Montserrat";
  font-weight: 700;
  padding-top: 88px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.eqry-btn {
  background-color: white !important;
  border-radius: 30px !important;
  border: white;
}

.now-btn {
  background: linear-gradient(
    97.97deg,
    #1672a1 0.32%,
    #0aa6d0 76.09%,
    #97dded 105.21%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 30px !important;
  border: white;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 35px;
  padding: 2px 14px;
}
