/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  #about {
    background-size: cover !important;
  }
  .about-data {
    text-align: center !important;
    width: auto !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  #about {
    background-size: cover !important;
  }
  .about-data {
    text-align: center !important;
    width: auto !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  #about {
    background-size: cover !important;
  }
  .about-data {
    text-align: center !important;
    width: auto !important;
  }
}

/* mac  */

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-data {
    padding-bottom: 70px !important;
  }
}

/* fullhd */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  #about {
    background-size: cover !important;
  }
  .about-data {
    padding-bottom: 50px !important;
  }
}

#about {
  background-image: url("../../assets/about/about-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.about {
  color: white;
  padding-top: 50px;
}

.about-data {
  color: #fff;
  font-family: "Poppins";
  line-height: 35.5px;
  padding-bottom: 35px;
  width: 75%;
  margin: auto;
  text-align: justify;
}
