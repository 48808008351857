/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  li {
    padding-top: 10px !important;
  }
  .nation-content {
    text-align: center !important;
  }
  .cost-subhead {
    font-size: 14px !important;
    padding: 10px 7px !important;
    line-height: 20px !important;
  }
  .country {
    width: 54% !important;
  }
}

@media screen and (min-width: 390px) and (max-width: 450px) {
  li {
    padding-top: 10px !important;
  }
  .nation-content {
    text-align: center !important;
  }
  .cost-subhead {
    font-size: 14px !important;
    padding: 10px 7px !important;
    line-height: 20px !important;
  }
  .country {
    width: 54% !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  li {
    padding-top: 10px !important;
  }
  .nation-content {
    text-align: center !important;
  }
  .country {
    width: 52% !important;
  }
}

.nation-modal .modal-content {
  background: white;
  height: 550px;
  overflow-y: scroll;
}

.nation-head {
  color: black;
  font-size: 26px;
  font-family: "Poppins";
  font-weight: 800;
  line-height: 33px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.nation-tag {
  color: black;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 33px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.nation-img {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.country {
  width: 45%;
}

.country {
  border-radius: 5px;
}

.d1 {
  margin-right: 15px;
}

.nation-subhead {
  color: #e59d03;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 28px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
}

.nation-content {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 28px;
  display: flex;
  justify-content: center;
  text-align: left;
  padding-top: 20px;
}

.why-head,
.cost-head {
  color: #e59d03;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 28px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.points {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.nation-modal-btn {
  background-color: white !important;
  border-radius: 30px !important;
  border: white;
  margin: auto;
  margin-bottom: 25px;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
}

.nation-button {
  background: linear-gradient(
    97.97deg,
    #1672a1 0.32%,
    #0aa6d0 76.09%,
    #97dded 105.21%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 30px !important;
  border: white;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 35px;
  padding: 2px 14px;
}

.btn-close {
  background-color: white;
}

.cost-subhead {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: white;
  padding: 10px 0px;
}

.t1,
.t2,
.c1,
.c2 {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: white;
  padding: 3px 0px;
}

.free {
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #494949;
}

.university-head {
  color: #e59d03;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 28px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

table {
  background-color: #09232f;
  width: 100%;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 2px;
}

.cost-table {
  padding-top: 25px;
}

.ctr {
  display: flex;
  justify-content: center;
}

/* li {
  list-style-image: url("../../../../assets/study/icon.svg");
  padding-top: 5px;
} */

li {
  list-style: none;
  list-style-position: outside;
}

li::before {
  content: url("../../../../assets/study/icon.svg");
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  margin-top: 5px;
}

.points {
  margin-left: -28px !important;
}
